import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, EffectFade, Pagination } from 'swiper'
// import { HiOutlineArrowLongLeft, HiOutlineArrowLongRight } from 'react-icons/hi2'
import Banner1 from './items/Banner1'
import Banner2 from './items/Banner2'
import Banner3 from './items/Banner3'
import Banner4 from './items/Banner4'
import './Banner.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/css'
import { useTranslation } from 'react-i18next'

export default () =>{
    const {t,i18n} = useTranslation()
    const swiperRef = useRef()
    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false)
    SwiperCore.use([Autoplay, Pagination, EffectFade])
    return(
        <div className='relative flex w-[100%] h-fit items-end bg-transparent'>
            {/* <div className='absolute flex w-[100%] h-[10px] bottom-[53px] items-center justify-center px-5 lg:px-16 py-10 lg:py-10 z-30'>
                <div className='w-[100%] pc-3:w-[1366px] inline-flex flex-nowrap overflow-hidden items-center h-[35px] bg-[#F4BA54] [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]'>
                    <p className='text-[12px] whitespace-nowrap text-[#204a63] font-customRegular [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll'>
                    {t('title.running5')}</p>

                    <p className='ml-14 text-[12px] whitespace-nowrap text-[#204a63] font-customRegular [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll'>
                    {t('title.running5')}</p>

                    <p className='ml-14 text-[12px] whitespace-nowrap text-[#204a63] font-customRegular [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll'>
                    {t('title.running5')}</p>
                </div>
            </div> */}

            <Swiper
            spaceBetween={0}
            slidesPerView={1}
            speed={1000}
            loop={true}
            touchRatio={1.5}
            effect="fade"
            modules={[Pagination]}
            pagination={{
                el: '.swiper-pagination',
                clickable: true,
                type: 'bullets',
                renderBullet: function () {
                    return(
                        `<span class="swiper-pagination-bullet"></span>`
                    )
                }
            }}
            autoplay={{ delay: 30000,
                disableOnInteraction: true, }}
            onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
            }}>
                {/* New logo */}
                {/* <SwiperSlide>
                        <div className='relative block pc-1:hidden'>
                            <div className='absolute flex top-[716px] w-[100%] h-10'>
                                <a role={'button'} href='https://www.facebook.com/ktakilat.id' className='w-[50%] h-[100%]' target='_blank'/>
                                <a role={'button'} href='https://www.instagram.com/ktakilat.id/' className='w-[50%] h-[100%]' target='_blank'/>
                            </div>
                            <img src={ i18n.language === 'en' ? require('../../../assets/images/home-header-mobile-eng.png') : require('../../../assets/images/home-header-mobile-indo.png')} className='h-[100%] bg-slate-500 object-cover'/>
                        </div>
                    
                    <div className='relative w-[100%] pc-1:h-screen pc-3:h-[675px] hidden pc-1:flex justify-center items-center'>
                        <img src={ i18n.language === 'en' ? require('../../../assets/images/home-header2-wide.png') : require('../../../assets/images/home-header2-wide-indo.png')} className='absolute block pc-3:hidden top-0 z-20 h-[calc(100vh-75px)] object-cover'/>
                        <img src={ i18n.language === 'en' ? require('../../../assets/images/home-header2-wide.png') : require('../../../assets/images/home-header2-wide-indo.png')} className='absolute hidden pc-3:block top-0 z-20 h-[600px] object-cover'/>
                        <div className='absolute top-0 w-[100%] h-[100%] bg-gradient-to-b from-[#fde5bc] via-[#E0A234] to-[#E77B30]'/>

                        <div className='flex gap-2 absolute w-[273px] h-9 z-40 top-[447px] left-[670px] pc-3:top-[520px] pc-3:left-[954px]'>
                            <a role={'button'} href='https://www.facebook.com/ktakilat.id' className={`w-[50%] h-[100%]`} target='_blank'/>
                            <a role={'button'} href='https://www.instagram.com/ktakilat.id/' className={`w-[50%] [100%]`} target='_blank'/>
                        </div>
                    </div>
                </SwiperSlide> */}

                <SwiperSlide>
                    {/* Mobile */}
                    <div className='block pc-1:hidden w-[100%] h-[830px] bg-gradient-to-b from-[#F4BA54] via-[#E0A234] to-[#E77B30] pt-28 pb-20 px-5'>
                        <img src={require('../../../assets/images/header-hand.png')} className='w-[100%] object-contain'/>

                        <div className={`${ i18n.language === 'en' ? 'hidden' : 'block' }`}>
                            <h1 className='text-[#204a63] text-custom-pc-banner-large3 font-customExtraBold -mb-2'>{t('title.banner1')}</h1>
                            <div class="text-[#204a63] text-custom-pc-banner-large3 font-customExtraBold">{t('title.banner2')} <span class="inline-flex flex-col
                            h-[calc(theme(fontSize.custom-pc-banner-large3)*theme(lineHeight.tight))] overflow-hidden">
                                <ul class="block animate-text-slide-4 text-left leading-tight [&_li]:block text-white">
                                    <li>{t('title.running1')}</li>
                                    <li>{t('title.running2')}</li>
                                    <li>{t('title.running3')}</li>
                                    <li>{t('title.running4')}</li>
                                    <li aria-hidden="true">{t('title.running1')}</li>
                                </ul>
                            </span></div>
                            <h1 className='text-[#204a63] text-custom-pc-banner-large3 font-customExtraBold -mt-2'>{t('title.banner3')}</h1>
                        </div>

                        <div className={`${ i18n.language === 'en' ? 'block' : 'hidden' }`}>
                            <h1 class="text-[#204a63] text-custom-pc-banner-large3 font-customExtraBold leading-10">{t('title.banner1')}&nbsp;
                            <span class="inline-flex flex-col
                            h-[calc(theme(fontSize.custom-pc-banner-large3)*theme(lineHeight.tight))] overflow-hidden">
                                <ul class="block animate-text-slide-4 text-left leading-tight [&_li]:block text-white">
                                    <li>{t('title.running1')}</li>
                                    <li>{t('title.running2')}</li>
                                    <li>{t('title.running3')}</li>
                                    <li>{t('title.running4')}</li>
                                    <li aria-hidden="true">{t('title.running1')}</li>
                                </ul>
                            </span>
                            </h1>
                            <h1 className='text-[#204a63] text-custom-pc-banner-large3 font-customExtraBold -mt-2 leading-10'>{t('title.banner2')}</h1>
                            <h1 className='text-[#204a63] text-custom-pc-banner-large3 font-customExtraBold -mt-2 leading-10'>{t('title.banner3')}</h1>
                        </div>

                        <p className='w-[100%] text-[#132d3b] text-custom-pc-bannertext font-customRegular mb-3'>{t('text.banner')}</p>
                    </div>

                    {/* PC */}
                    <div className='hidden pc-1:flex w-[100%] pc-1:h-[683px] pc-3:h-[710px] bg-gradient-to-b from-[#F4BA54] via-[#E0A234] to-[#E77B30] pt-28 pb-20 px-5 justify-center'>
                        <div className='flex w-[100%] pc-3:w-[1366px] px-[40px]'>
                        <div className='w-[50%] ml-10'>
                            <div className={`${ i18n.language === 'en' ? 'hidden' : 'block' }`}>
                                <h1 className='text-[#204a63] text-custom-pc-banner-large1 font-customExtraBold -mb-3'>{t('title.banner1')}</h1>
                                <div class="text-[#204a63] text-custom-pc-banner-large1 font-customExtraBold">{t('title.banner2')} <span class="inline-flex flex-col
                                h-[calc(theme(fontSize.custom-pc-banner-large1)*theme(lineHeight.tight))] overflow-hidden">
                                    <ul class="block animate-text-slide-4 text-left leading-tight [&_li]:block text-white">
                                        <li>{t('title.running1')}</li>
                                        <li>{t('title.running2')}</li>
                                        <li>{t('title.running3')}</li>
                                        <li>{t('title.running4')}</li>
                                        <li aria-hidden="true">{t('title.running1')}</li>
                                    </ul>
                                </span></div>
                                <h1 className='text-[#204a63] text-custom-pc-banner-large1 font-customExtraBold -mt-3'>{t('title.banner3')}</h1>
                            </div>

                            <div className={`${ i18n.language === 'en' ? 'block' : 'hidden' }`}>
                                <h1 class="text-[#204a63] text-custom-pc-banner-large1 font-customExtraBold">{t('title.banner1')}&nbsp;
                                <span class="inline-flex flex-col
                                h-[calc(theme(fontSize.custom-pc-banner-large1)*theme(lineHeight.tight))] overflow-hidden">
                                    <ul class="block animate-text-slide-4 text-left leading-tight [&_li]:block text-white">
                                        <li>{t('title.running1')}</li>
                                        <li>{t('title.running2')}</li>
                                        <li>{t('title.running3')}</li>
                                        <li>{t('title.running4')}</li>
                                        <li aria-hidden="true">{t('title.running1')}</li>
                                    </ul>
                                </span>
                                </h1>
                                <h1 className='text-[#204a63] text-custom-pc-banner-large1 font-customExtraBold -mt-3'>{t('title.banner2')}</h1>
                                <h1 className='text-[#204a63] text-custom-pc-banner-large1 font-customExtraBold -mt-3'>{t('title.banner3')}</h1>
                            </div>
                            <p className='w-[80%] text-[#132d3b] text-custom-pc-bannertext font-customRegular mb-3'>{t('text.banner')}</p>

                            <div className='w-[100%] pc-3:w-[1366px] mt-[16px] pc-3:mt-[63px]'>
                                <div className='hidden pc-1:block w-fit'>
                                    <span className='text-[#ffffff] text-custom-mobile-subbanner-large1 font-customBold'>{t('download.banner')}</span>
                                    <div className='flex w-[410px] h-[51.82px] mt-2'>
                                        <div className='flex h-[51.82px] gap-3' >
                                            <a href='https://play.google.com/store/apps/details?id=com.ktakilat.loan' target='_blank'>
                                                <img src={require('../../../assets/images/android.png')}/>
                                            </a>

                                            <a href='https://apps.apple.com/id/app/kta-kilat-pinjaman-cepat-cair/id6472646842' target='_blank'>
                                                <img src={require('../../../assets/images/ios.png')}/>
                                            </a>
                                        </div>
                                        <div className='absolute w-[33px] h-[33px] mt-[10.5px] ml-[161.35px] cursor-pointer rounded-md'
                                        onMouseEnter={()=>setShow1(true)}
                                        onMouseLeave={()=>setShow1(false)}/>
                                        
                                        <div className='absolute w-[33px] h-[33px] mt-[10.5px] ml-[363px] cursor-pointer rounded-md'
                                        onMouseEnter={()=>setShow2(true)}
                                        onMouseLeave={()=>setShow2(false)}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='w-[50%]'>
                            <img src={require('../../../assets/images/header-hand.png')} className='w-[100%] object-contain'/>
                        </div>
                    </div>
                    </div>

                    <div className='block pc-1:hidden absolute bottom-36 mt-5 px-5 w-[100%] h-[51.82px] z-10'>
                    <span className='text-[#ffffff] text-custom-mobile-subbanner-large1 font-customBold'>{t('download.banner')}</span>
                        <div className='flex h-[51.82px] gap-3' >
                            <a href='https://play.google.com/store/apps/details?id=com.ktakilat.loan' target='_blank'>
                                <img src={require('../../../assets/images/android.png')}/>
                            </a>

                            <a href='https://apps.apple.com/id/app/kta-kilat-pinjaman-cepat-cair/id6472646842' target='_blank'>
                                <img src={require('../../../assets/images/ios.png')}/>
                            </a>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    {/* Mobile */}
                    <div className='block pc-1:hidden w-[100%] h-[765px] bg-gradient-to-b from-[#F4BA54] via-[#E0A234] to-[#E77B30] pt-28 pb-20 px-5'>
                        <img src={require('../../../assets/images/header-hand2.png')} className='w-[100%] object-contain'/>

                        <div className={`${ i18n.language === 'en' ? 'hidden' : 'block' } pt-7`}>
                            <img src={require('../../../assets/images/frame_baru.png')} className='h-[50px] object-contain'/>
                            <h1 className='text-[#204a63] text-custom-pc-banner-large3 font-customExtraBold'>{t('title.banner1.ios')}</h1>
                            <h1 className='text-[#204a63] text-custom-pc-banner-large3 font-customExtraBold -mt-3'>{t('title.banner2.ios')}</h1>
                            <h1 className='text-[#204a63] text-custom-pc-banner-large3 font-customExtraBold -mt-3'>{t('title.banner3.ios')}</h1>
                        </div>

                        <div className={`${ i18n.language === 'en' ? 'block' : 'hidden' } pt-7`}>
                        <img src={require('../../../assets/images/frame_new.png')} className='h-[50px] object-contain'/>
                            <h1 className='text-[#204a63] text-custom-pc-banner-large3 font-customExtraBold'>{t('title.banner1.ios')}</h1>
                            <h1 className='text-[#204a63] text-custom-pc-banner-large3 font-customExtraBold -mt-3'>{t('title.banner2.ios')}</h1>
                            <h1 className='text-[#204a63] text-custom-pc-banner-large3 font-customExtraBold -mt-3'>{t('title.banner3.ios')}</h1>
                        </div>
                    </div>

                    {/* PC */}
                    <div className='hidden pc-1:flex w-[100%] pc-1:h-screen pc-3:h-[675px] bg-gradient-to-b from-[#F4BA54] via-[#E0A234] to-[#E77B30] pt-28 pb-20 px-5 justify-center'>
                        <div className='flex w-[100%] pc-3:w-[1366px] px-[40px]'>
                        <div className='w-[50%] mt-5 pc-1:mt-2 pc-3:mt-2 ml-10'>
                            <div className={`${ i18n.language === 'en' ? 'hidden' : 'block' }`}>
                                <img src={require('../../../assets/images/frame_baru.png')} className='h-[60px] object-contain'/>
                                <h1 className='text-[#204a63] text-custom-pc-banner-large1 font-customExtraBold'>{t('title.banner1.ios')}</h1>
                                <h1 className='text-[#204a63] text-custom-pc-banner-large1 font-customExtraBold -mt-3'>{t('title.banner2.ios')}</h1>
                                <h1 className='text-[#204a63] text-custom-pc-banner-large1 font-customExtraBold -mt-3'>{t('title.banner3.ios')}</h1>
                            </div>

                            <div className={`${ i18n.language === 'en' ? 'block' : 'hidden' }`}>
                                <img src={require('../../../assets/images/frame_new.png')} className='h-[60px] object-contain'/>
                                <h1 class="text-[#204a63] text-custom-pc-banner-large1 font-customExtraBold">{t('title.banner1.ios')}</h1>
                                <h1 className='text-[#204a63] text-custom-pc-banner-large1 font-customExtraBold -mt-3'>{t('title.banner2.ios')}</h1>
                                <h1 className='text-[#204a63] text-custom-pc-banner-large1 font-customExtraBold -mt-3'>{t('title.banner3.ios')}</h1>
                            </div>

                            <div className='w-[100%] pc-3:w-[1366px] mt-[35px] pc-3:mt-[56px]'>
                                <div className='hidden pc-1:block w-fit'>
                                    <span className='text-[#ffffff] text-custom-mobile-subbanner-large1 font-customBold'>{t('download.banner')}</span>
                                    <div className='flex w-[410px] h-[51.82px] mt-[9px]'>
                                        <div className='flex h-[51.82px] gap-3' >
                                            <a href='https://play.google.com/store/apps/details?id=com.ktakilat.loan' target='_blank'>
                                                <img src={require('../../../assets/images/android.png')}/>
                                            </a>

                                            <a href='https://apps.apple.com/id/app/kta-kilat-pinjaman-cepat-cair/id6472646842' target='_blank'>
                                                <img src={require('../../../assets/images/ios.png')}/>
                                            </a>
                                        </div>
                                        <div className='absolute w-[33px] h-[33px] mt-[10.5px] ml-[161.35px] cursor-pointer rounded-md'
                                        onMouseEnter={()=>setShow1(true)}
                                        onMouseLeave={()=>setShow1(false)}/>
                                        
                                        <div className='absolute w-[33px] h-[33px] mt-[10.5px] ml-[363px] cursor-pointer rounded-md'
                                        onMouseEnter={()=>setShow2(true)}
                                        onMouseLeave={()=>setShow2(false)}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='w-[100%]'>
                            <img src={require('../../../assets/images/header-hand2.png')} className='w-[90%] pc-3:w-[100%] object-contain'/>
                        </div>
                    </div>
                    </div>

                    <div className='block pc-1:hidden absolute bottom-28 mt-5 px-5 w-[100%] h-[51.82px] z-10'>
                    <span className='text-[#ffffff] text-custom-mobile-subbanner-large1 font-customBold'>{t('download.banner')}</span>
                        <div className='flex h-[51.82px] gap-3' >
                            <a href='https://play.google.com/store/apps/details?id=com.ktakilat.loan' target='_blank'>
                                <img src={require('../../../assets/images/android.png')}/>
                            </a>

                            <a href='https://apps.apple.com/id/app/kta-kilat-pinjaman-cepat-cair/id6472646842' target='_blank'>
                                <img src={require('../../../assets/images/ios.png')}/> 
                            </a>
                        </div>
                    </div>
                </SwiperSlide>
                {/* <div className='flex pc-1:hidden absolute bottom-28 mt-5 px-5 w-[100%] h-[51.82px] z-10'>
                    <div className='flex h-[51.82px] gap-3' >
                        <a href='https://play.google.com/store/apps/details?id=com.ktakilat.loan' target='_blank'>
                            <img src={require('../../../assets/images/android.png')}/>
                        </a>

                        <a href='https://apps.apple.com/id/app/kta-kilat-pinjaman-cepat-cair/id6472646842' target='_blank'>
                            <img src={require('../../../assets/images/ios.png')}/>
                        </a>
                    </div>
                </div> */}
            </Swiper>

            <div className={`${show1? 'block' : 'hidden'} absolute w-[150px] h-[150px] top-[265px] left-[125px] pc-3:top-[290px] pc-3:left-[295px] shadow-2xl border-gray-50 rounded-2xl z-50`}>
                <img src={require('../../../assets/images/qr.png')}/>
            </div>

            <div className={`${show2 ? 'block' : 'hidden'} absolute w-[150px] h-[150px] top-[265px] left-[337px] pc-3:top-[290px] pc-3:left-[500px] shadow-2xl border-gray-50 rounded-2xl z-50`}>
                <img src={require('../../../assets/images/qr_ios.png')}/>
            </div>
            <div className='absolute w-[100%] h-[75px] bottom-0 rounded-t-[50px] bg-white z-20'>
            </div>
        </div>

        // <>
        //     <Banner4/>
        // </>
    )
}