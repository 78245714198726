import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import apk from '../../assets/documents/KTAKilat.apk'

export default()=>{
    const {t,i18n} = useTranslation()
    const [show, setShow] = useState(false)
    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false)
    return(
        <>
        <div style={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${require('../../assets/images/test.png')})`
        }}
        className={`hidden sm:flex w-[100%] h-[550px] justify-center`}>
            <div className='relative flex w-[100%] pc-3:w-[1366px] h-[100%]'>
                <div className='flex w-[50%] h-[550px] items-center justify-center z-30'>
                    <div className='w-[70%] items-center'>
                        {/* <h6 className='text-white text-custom-mobile-banner-large2 font-customExtraBold mt-7'>TKB 90=97%</h6> */}
                        <h1 className='leading-tight text-[32px] text-colorBlueDark font-customExtraBold mb-3'>{t('title.banner')}</h1>
                        <p className='text-custom-mobile-text pc-1:text-custom-pc-text text-colorBlueDark font-customRegular mb-5'>{t('text.banner')}</p>
                        {/* <span className='text-[#ffffff] text-custom-mobile-subbanner-large1 font-customBold'>{t('download.banner')}</span> */}
                        {/* <div className='flex w-fit mt-3'>
                            <div className='flex w-[200px] h-[100%]'>
                                <div className='flex w-[200px] h-[100%]' >
                                    <a href='https://play.google.com/store/apps/details?id=com.ktakilat.loan' target='_blank'>
                                        <img src={require('../../assets/images/android.png')}/>
                                    </a>
                                </div>
                                <div className='absolute w-[33px] h-[33px] mt-[8.5px] ml-[154px] cursor-pointer'
                                onMouseEnter={()=>setShow(!show)}
                                onMouseLeave={()=>setShow(!show)}/>
                            </div>
                            
                            <div className={`${show ? 'block' : 'hidden'} absolute w-[150px] h-[150px] ml-[215px] shadow-2xl border-gray-50 rounded-2xl`}>
                                <img src={require('../../assets/images/qr.png')}/>
                            </div>
                        </div> */}

                        <div className='w-fit z-30'>
                            <span className='text-[#ffffff] text-custom-mobile-subbanner-large1 font-customBold'>{t('download.banner')}</span>
                            <div className='flex w-[410px] h-[51.82px] mt-2'>
                                <div className='flex h-[51.82px] gap-3' >
                                    <a href='https://play.google.com/store/apps/details?id=com.ktakilat.loan' target='_blank'>
                                        <img src={require('../../assets/images/android.png')}/>
                                    </a>

                                    <a href='https://apps.apple.com/id/app/kta-kilat-pinjaman-cepat-cair/id6472646842' target='_blank'>
                                    <img src={require('../../assets/images/ios.png')}/>
                                    </a>
                                </div>
                                <div className='absolute w-[33px] h-[33px] mt-[9px] ml-[155px] cursor-pointer'
                                onMouseEnter={()=>setShow1(!show1)}
                                onMouseLeave={()=>setShow1(!show1)}/>
                                
                                <div className='absolute w-[33px] h-[33px] mt-[9px] ml-[365px] cursor-pointer'
                                onMouseEnter={()=>setShow2(!show2)}
                                onMouseLeave={()=>setShow2(!show2)}/>
                            </div>

                            <div className={`${show1? 'block' : 'hidden'} absolute w-[150px] h-[150px] ml-[25px] mt-3 shadow-2xl border-gray-50 rounded-2xl z-50`}>
                                <img src={require('../../assets/images/qr.png')}/>
                            </div>

                            <div className={`${show2 ? 'block' : 'hidden'} absolute w-[150px] h-[150px] ml-[235px] mt-3 shadow-2xl border-gray-50 rounded-2xl z-50`}>
                                <img src={require('../../assets/images/qr_ios.png')}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='absolute w-auto h-auto right-0 bottom-0'>
                    <img src={require('../../assets/images/footer-pic.png')} alt='home-download' className='hidden pc-1:block w-auto object-cover'/>
                    <img src={require('../../assets/images/footer-pic.png')} alt='home-download' className='block pc-1:hidden h-[480px] object-cover'/>
                </div>
            </div>
        </div>

        <div style={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${require('../../assets/images/mobile-poster-bg.png')})`
        }}
        className={`relative block sm:hidden w-[100%] min-h-[730px] justify-center px-5 pt-10`}>
            <div className='w-[100%] h-[60%]'>
                {/* <h6 className='text-white text-custom-mobile-banner-large2 font-customExtraBold mt-7'>TKB 90=97%</h6> */}
                <h1 className='text-[#204a63] text-custom-mobile-banner-large1 font-customExtraBold'>{t('title.banner')}</h1>
                <p className='text-custom-mobile-text pc-1:text-custom-pc-text text-colorBlueDark font-customRegular mb-5'>{t('text.banner')}</p>

                {/* <span className='text-[#ffffff] text-custom-mobile-subbanner-large1 font-customBold'>{t('download.banner')}</span>
                <div className='flex w-fit mt-3'>
                    <div className='flex w-[200px] h-[51.82px]'>
                        <div className='flex w-[200px] h-[51.82px]' >
                            <a href='https://play.google.com/store/apps/details?id=com.ktakilat.loan' target='_blank'>
                                <img src={require('../../assets/images/android.png')}/>
                            </a>
                        </div>
                    </div>
                </div> */}

                <div>
                    <span className='text-[#ffffff] text-custom-mobile-subbanner-large1 font-customBold'>{t('download.banner')}</span>
                    <div className='flex w-[100%] h-[51.82px] mt-2'>
                        <div className='flex h-[51.82px] gap-3' >
                            <a href='https://play.google.com/store/apps/details?id=com.ktakilat.loan' target='_blank'>
                                <img src={require('../../assets/images/android.png')}/>
                            </a>

                            <a href='https://apps.apple.com/id/app/kta-kilat-pinjaman-cepat-cair/id6472646842' target='_blank'>
                                <img src={require('../../assets/images/ios.png')}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-[100%] h-[40%]'>
                <img src={require('../../assets/images/home-download-mobile.png')} alt='home-download' className='absolute bottom-0 object-cover w-[90%]'/>
            </div>
        </div>
        </>
    )
}