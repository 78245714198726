import './App.css'
import React, { useState, useEffect, useRef } from 'react'
import {Route,Routes,Outlet} from 'react-router-dom'
import Navbar from './pages/navbar/Navbar'
import Home from './pages/home/Home'
import About from './pages/about/About'
import News from './pages/news/News'
import Faq from './pages/faq/Faq'
import LifeAtPendanaan from './pages/lifeatpendanaan/LifeAtPendanaan'
import Footer from './pages/footer/Footer'
import Lender from './pages/lender/Lender'
import Complaint from './pages/complaint/Complaint'
import ScrollToTop from './pages/ScrollToTop'
import StepContext from './pages/lender/application/Step-Context'
import { IoMdClose } from "react-icons/io";
import { isCancel } from 'axios'
import { CgCloseO  } from 'react-icons/cg'
import { useTranslation } from 'react-i18next'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import Notfound from './pages/notfound/notfound'

function WithNav () {
  const [isShowing, setIsShowing] = useState(true)
  const [isWarning, setIsWarning] = useState(true)
  const [isPopup, setIsPopup] = useState(true)

  const showFAB = () =>{
      if(window.scrollY >= 80){
        setIsShowing(true);
      }
      else{
        setIsShowing(true);
      }
  }
  window.addEventListener('scroll', showFAB)
  const {t,i18n} = useTranslation()

  const box = useRef(null);
  useOutsideAlerter(box, setIsPopup);
  return (
    <>
    <div className={`fixed pin w-screen h-screen top-0 z-50 overflow-y-hidden bg-smoke-light ${isPopup? 'no-doc-scroll block' : 'hidden'}`}>
        {/* <div className='relative w-[500px] h-fit  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' */}
        <div className='relative w-[95%] pc-1:w-[500px] h-fit top-1/3 pc-1:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/3 pc-1:-translate-y-1/2'
        ref={box}>
          <button className='absolute w-[25px] text-[22px] text-slate-300 h-[25px] top-0 right-0 cursor-pointer'
          onClick={() => {
            setIsPopup(false)
          }}><IoMdClose/></button>
          <img src={require('../src/assets/images/popup.png')} alt='pendanaan-1' className='w-[100%] object-cover hidden pc-1:block'/>
          <img src={require('../src/assets/images/popup-mobile.png')} alt='pendanaan-1' className='w-[100%] object-cover block pc-1:hidden'/>
        </div>

        {/* <div className='relative w-[95%] h-fit top-1/3 mobile-3:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 block pc-1:hidden'>
        <button className='absolute w-[25px] text-[22px] text-slate-300 h-[25px] top-0 right-0 cursor-pointer'
          onClick={() => {
            setIsPopup(false)
          }}><IoMdClose/></button>
          <img src={require('../src/assets/images/popup-mobile.png')} alt='pendanaan-1' className='w-[100%] object-cover'/>
        </div> */}
    </div>
    
    <div className={`fixed ${isWarning? 'flex' : 'hidden'} justify-center items-center top-0 left-0 w-screen h-[275px] pc-1:h-[150px] p-5 z-40 bg-[#F6F6F6] font-customRegular text-center`}>
      <a onClick={()=>{
        setIsWarning(false)
      }} className='absolute top-5 pc-1:top-[60px] right-3 pc-1:right-[275px] pc-3:right-[400px] cursor-pointer'>
        <CgCloseO />
      </a>
      
      <div>
        <p className='w-[100%] pc-3:w-[1366px] text-[#E77B30] text-[16px]'><b>{t('fraud.warning1')}</b></p>
        <p className='text-[12px] mt-3'><b>PT PENDANAAN TEKNOLOGI NUSA</b> {t('fraud.warning2')} <b>KTA KILAT</b><br />{t('fraud.warning3')}<br />{t('fraud.warning4')}</p>
        <CustomLink to = '/faq' className='text-[#E77B30] font-customRegular text-[12px] underline' onClick={()=>{setIsWarning(false)}}>{t('close.button')}</CustomLink>
      </div>
    </div>
    <div className={`${isWarning? 'mt-[275px] pc-1:mt-[150px]' : 'mt-[0px]'}`}>
      <Navbar />
      <Outlet />
      <Footer/>
      <CustomLink to="/complaint" className={`${isShowing? 'flex' : 'hidden'} shadow-gray-700 drop-shadow-lg act-btn transform delay-150 z-20`}>
        <img src={require('../src/assets/images/actButt.png')} alt='pendanaan-1' className='w-[100%] object-cover'/>
      </CustomLink>
    </div>
    </>
  )
}

function WithNoNav () {
  return (
    <>
      <Outlet />
    </>
  )
}

function App() {
  const [isClick, setIsClick1] = useState(null)
  const {t,i18n} = useTranslation()
  const items = [{
      'question':`${t('first.faq.loan.question1.title')}`,
      'answer':<div>{t('first.faq.loan.question1.text1')}</div>,
      'h':65,
      'mb':'5px',
      'c':true
  },{
    'question':`${t('first.faq.loan.question1.title')}`,
    'answer':<div>{t('first.faq.loan.question1.text1')}</div>,
    'h':365,
    'mb':'5px',
    'c':true
}]
  return (
    <>
    <ScrollToTop />
      <Routes>
        <Route element={<WithNav />}>
          <Route path='/' element={<Home/>}/>
          <Route path='/about-us' element={<About/>}/>
          <Route path='/news' element={<News/>}/>
          <Route path='/faq' element={<Faq/>}/>
          <Route path='/life-at-pendanaan' element={<LifeAtPendanaan/>}/>
          <Route path='/lender' element={<Lender/>}/>
          <Route path='/complaint' element={<Complaint/>}/>
          <Route path='*' element={<Notfound/>}/>
        </Route>

        <Route element={<WithNoNav />}>
          <Route path='/lender-app' element={<StepContext/>}/>
        </Route>
      </Routes>
    </>
  )
}

function CustomLink({ to, children, setIsWarning ,...props }){
  const resolvePath = useResolvedPath(to)
  const isActive = useMatch({path: resolvePath.pathname, end: true})
  return(
      <li className = {`${isActive ? 'active' : ''} list-none`}>
          <Link to = {to} {...props}>{children} </Link>
      </li>
  )
}

function useOutsideAlerter(ref, setIsPopup) {
  useEffect(() => {
    function handleOutsideClick(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("you just clicked outside of box!");
        setIsPopup(false)
      }
    }
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [ref, setIsPopup]);
}

export default App
